import type {AlertColor} from "@mui/material";

// refactor to only use MuiAlertType?
export enum AtgAlertTypes {
    WARNING = "warning",
    DANGER = "danger",
    INFO = "info",
    SUCCESS = "success",
}
export type AtgAlertType = `${AtgAlertTypes}`;

export type MuiAlertType = AlertColor | undefined;
export enum MuiAlertTypes {
    WARNING = "warning",
    ERROR = "error",
    INFO = "info",
    SUCCESS = "success",
}

export enum AlertTags {
    REDUCERAT = "Reducerat",
    ATGSE = "atgse",
    HARRYBOY_ALERT = "harryboy-alert",
    Tillsammans_SEARCH_PAGE = "tillsammans-searchpage",
    SHOPS_PUBLIC = "shops-public",
    SHOPS_ADMIN = "shops-admin",
    DEPOSIT_METHOD = "depositMethod",
}

// reference @atg-global-shared/horse-contentful-hooks/useGetAlertsByTags.ts
export type AlertPropsType = {
    id: string;
    title: string;
    alertLevel: AtgAlertType;
    games: Array<string>;
    message: string;
    tags: Array<string>;
    link: string | null;
};
