import root from "window-or-global";
import {get} from "lodash";
import {device} from "@atg/utils";
import {isAndroid} from "@atg/utils/device";

const environment = root?.clientConfig?.env ?? "qa";
const atgApiSubdomain = `api${environment !== "prod" ? `.${environment}.hh` : ""}`;
const isApp = root.clientConfig?.systemName === "ATGApp";

export const getAccessChannel = (): string => {
    if (!isApp) return "web";

    return isAndroid() ? "android" : "ios";
};

export const getOriginRoot = () => {
    const origin =
        (isApp ? get(root, "clientConfig.AWS_MOBILE_APP_URL") : root.location.origin) ||
        "";

    // enable launching game through localhost
    if (origin.includes("localhost")) return "https://www.test3.hh.atg.se";

    return origin;
};

export const getDeviceType = () => {
    // This is needed for qlikview to determine that the start
    // came from the mobile app.
    if (isApp) {
        return "atgapp";
    }

    // This is for atg.se mobile and not atgapp
    if (device.isDevice()) {
        return "mobile";
    }

    return "desktop";
};

export const getBaseV2Url = (privateEndpoint: boolean, gateway: string) =>
    privateEndpoint
        ? `/services/tokenized/${gateway}/api/v2/launch`
        : `https://${atgApiSubdomain}.atg.se/${gateway}/api-public/v2/launch`;
